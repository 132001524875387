import { render, staticRenderFns } from "./AboutTemplate.vue?vue&type=template&id=197a567f&scoped=true"
import script from "./AboutTemplate.vue?vue&type=script&lang=js"
export * from "./AboutTemplate.vue?vue&type=script&lang=js"
import style0 from "./AboutTemplate.vue?vue&type=style&index=0&id=197a567f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197a567f",
  null
  
)

export default component.exports